<template>
  <div class="page-user-familyHome">
    <div class="tab-box">
      <div
        class="tab-item"
        v-for="(num, index) in tabs"
        :key="index"
        :class="{ active: index === tabNum }"
        @click="tabClick(index)"
      >
        {{ num }}
      </div>
    </div>
    <!-- <div class="my-page">
      <div class="my-card" v-for="(item, i) in 4" :key="i">
        <div class="top">
          <span class="type1">小二班</span>
          <span class="title">请家长帮助宝贝完成手工作业</span>
        </div>
        <div class="middle">
          <div class="middle-left">
            <div class="push">
              <van-icon name="checked" color="#999999" size="16" />
              已提交15
            </div>
            <div class="comment">
              <van-icon name="chat" color="#999999" size="16" />
              评论20
            </div>
          </div>
          <div class="middle-right">2021年11月30日</div>
        </div>
        <div class="bottom">
          <van-image width="100" height="74" lazy-load src="https://img01.yzcdn.cn/vant/cat.jpeg" @click="() => {
            images = []
            images.push('https://img01.yzcdn.cn/vant/cat.jpeg')
            imgShow = true
          }" style="margin-right: 10px;border-radius: 6px;overflow: hidden;display: inline-block;" />
          <van-image width="100" height="74" lazy-load src="https://img01.yzcdn.cn/vant/cat.jpeg" @click="() => {
            images = []
            images.push('https://img01.yzcdn.cn/vant/cat.jpeg')
            imgShow = true
          }" style="margin-right: 10px;border-radius: 6px;overflow: hidden;display: inline-block;" />
          <van-image width="100" height="74" lazy-load src="https://img01.yzcdn.cn/vant/cat.jpeg" @click="() => {
            images = []
            images.push('https://img01.yzcdn.cn/vant/cat.jpeg')
            imgShow = true
          }" style="margin-right: 10px;border-radius: 6px;overflow: hidden;display: inline-block;" />
          <van-image width="100" height="74" lazy-load src="https://img01.yzcdn.cn/vant/cat.jpeg" @click="() => {
            images = []
            images.push('https://img01.yzcdn.cn/vant/cat.jpeg')
            imgShow = true
          }" style="margin-right: 10px;border-radius: 6px;overflow: hidden;display: inline-block;" />
          <van-image width="100" height="74" lazy-load src="https://img01.yzcdn.cn/vant/cat.jpeg" @click="() => {
            images = []
            images.push('https://img01.yzcdn.cn/vant/cat.jpeg')
            imgShow = true
          }" style="margin-right: 10px;border-radius: 6px;overflow: hidden;display: inline-block;" />
          <van-image width="100" height="74" lazy-load src="https://img01.yzcdn.cn/vant/cat.jpeg" @click="() => {
            images = []
            images.push('https://img01.yzcdn.cn/vant/cat.jpeg')
            imgShow = true
          }" style="margin-right: 10px;border-radius: 6px;overflow: hidden;display: inline-block;" />

        </div>
      </div>
    </div> -->
    <!-- 悬浮框 -->
    <!-- <div class="fixed-add" @click="add">+</div> -->
    <class-room v-if="tabNum === 0" />
    <folder v-if="tabNum === 1" />
    <baby-circle v-if="tabNum === 2" />
    <!-- 菜单栏 -->
    <NavBar v-bind:active="1" />
    <!-- 图片预览 -->
    <van-image-preview v-model="imgShow" :images="images"> </van-image-preview>
  </div>
</template>

<script>
import defaultImg from '@/assets/icon/icon-my.png'
import NavBar from '@/components/NavBar'
import { Icon, Image, ImagePreview } from 'vant'
import ClassRoom from './ClassRoom/List'
import Folder from './Folder/List'
import BabyCircle from './BabyCircle/List'

export default {
  components: {
    NavBar,
    ClassRoom,
    Folder,
    BabyCircle,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data () {
    return {
      defaultImg: defaultImg,
      imgShow: false,
      images: [],
      tabs: ['班级圈', '文件夹', '宝贝圈'],
      tabNum: 0
    }
  },
  mounted () {
    // this.getUser()
    if (this.$route.query.active) {
      this.tabNum = Number(this.$route.query.active)
    }
  },
  methods: {
    tabClick (index) {
      this.tabNum = index
    },
    add () {
      this.$toast('功能正在开发中...')
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
