<template>
  <div class="page-classroom">
    <div class="my-page">
      <div class="list-box">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getDataList"
            offset="10"
          >
            <div
              class="my-card"
              v-for="(item, i) in dataList"
              :key="i"
              @click="handleClick(item)"
            >
              <div class="card-top">
                <div class="card-title ell">{{ item.Title }}</div>
                <div class="down-box" @click.stop="openOrClose">
                  <i class="i-up"></i>
                </div>
              </div>
              <div class="card-center">
                <span class="type2">{{ item.ChildrenName }}</span>
                <span class="date">{{ item.CreateDate }}</span>
              </div>
              <div class="card-bottom">
                <div class="content">
                  {{ item.Content }}
                </div>
                <div class="img-d">
                  <van-image
                    v-for="(img, index) in item.AccessPostList"
                    :key="index"
                    width="100"
                    height="74"
                    :src="img.url"
                    @click.stop="
                      () => {
                        images = []
                        images.push(img.url)
                        imgShow = true
                      }
                    "
                    style="margin-right: 10px;margin-bottom: 10px;border-radius: 6px;overflow: hidden;display: inline-block;"
                  />
                </div>
                <div class="comment-d">
                  <div class="right">
                    <div class="comment">
                      <i class="i-comment"></i>
                      <span class="num">评论{{ item.CommentCount }}</span>
                    </div>
                    <div class="good">
                      <i class="i-good"></i>
                      <span class="num">点赞{{ item.ThumbUpCount }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <!-- 图片预览 -->
    <van-image-preview v-model="imgShow" :images="images"> </van-image-preview>
    <!-- 新增悬浮按钮 -->
    <!-- <div v-if="userType === '2'" class="add-btn" @click="add">+</div> -->
  </div>
</template>

<script>
import { Button, List, PullRefresh, Image, ImagePreview } from 'vant'
export default {
  components: {
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data () {
    return {
      userType: window.localStorage.getItem('UserType'),
      loading: false,
      finished: false, // false
      refreshing: false,
      dataList: [],
      page: 0, // 页码
      limit: 10, // 每页条数
      imgShow: false,
      images: []
    }
  },
  mounted () {
    // 空白时候自动触发
    // this.getDataList()
  },
  methods: {
    getDataList () {
      this.page++
      this.getList()
    },
    getList () {
      const that = this
      that.$axios
        .get('/api/ChildrenCircle/List', {
          page: that.page,
          limit: that.limit
        })
        .then(res => {
          if (res.code === 200) {
            that.refreshing = false
            that.loading = false
            that.dataList = that.dataList.concat(res.data)
            if (that.dataList.length >= res.count) {
              that.finished = true
            }
          } else {
            that.$toast.fail(res.msg || '操作失败')
          }
        })
    },
    // 下拉刷新
    onRefresh () {
      this.page = 0
      // 清空列表数据
      this.finished = false
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true
      this.dataList = []
      this.getDataList()
    },
    handleClick (item) {
      this.$router.push({
        path: 'BabyCircleView',
        query: {
          id: item.ID
        }
      })
    },
    openOrClose (e) {
      const dom = this.$jq(e.currentTarget).find('i')
      if (dom.hasClass('i-down')) {
        dom.removeClass('i-down')
        dom
          .parent()
          .parent()
          .siblings('.card-bottom')
          .show()
      } else {
        dom.addClass('i-down')
        dom
          .parent()
          .parent()
          .siblings('.card-bottom')
          .hide()
      }
    },
    add () {
      this.$router.push('ClassRoomEdit')
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
